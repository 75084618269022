<template>
  <div class="beneficiaireRegister" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Créer votre compte</h1>
        <div class="form_div">
          <div class="form_content">


            <div v-if="!isCreated">
              <h2>
                Créer votre compte et accéder à votre profil
              </h2>

              <!-- ERROR MODAL -->

              <b-modal ref="beneficiaireCreationAPIErrorModal" id="beneficiaireCreationAPIErrorModal" hide-footer title="Erreur lors la création de votre compte">
                <div class="text-center">
                  <p>Une erreur s'est produite lors du chargement des informations de votre profil,
                    veuillez vérifier les données renseignées ou nous contacter directement.
                  </p>
                  <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('beneficiaireCreationAPIErrorModal')">Fermer</b-button>
                </div>
              </b-modal>

              <b-modal ref="munaExistingErrorModal" id="munaExistingErrorModal" hide-footer title="Numéro de Muna déjà existant" size="lg">
                <div class="text-center">
                  <p>Un bénéficiaire avec ce numéro de Muna s'est déjà inscrit sur la plateforme.</p>
                  <p> Veuillez vérifier votre numéro de Muna et, le cas échéant, ne pas le renseigner pour vous inscrire.</p>
                  <b-button class="mt-3" variant="outline-success" @click="$bvModal.hide('munaExistingErrorModal')">Fermer</b-button>
                </div>
              </b-modal>

              <div class="text-right">
                <small><em> <span style="color: red;">*</span>Champs obligatoires</em></small>
              </div>
              <b-form>
                <b-form-group id="new-beneficiaire-username-group" label="Votre email" label-for="new-beneficiaire-username-input" class="mandatory-input">
                  <b-form-input
                    v-model="username" type="email"
                    maxlength="149"
                    placeholder="Email"
                    id="new-beneficiaire-username-input"
                    @input="$v.username.$touch()"
                    :state="$v.username.$dirty ? !$v.username.$error : null">
                  </b-form-input>
                  <small class="text-info"> Il s'agira de votre identifiant pour vous connecter à votre compte</small><br>
                  <small class="text-danger" v-if="isExistingUserUsername"> {{ username }} existe déjà. Veuillez choisir un autre nom d'utilisateur</small>
                  <div class="form-error" v-if="!$v.username.email">Veuillez renseigner un format d'email valide.</div>
                </b-form-group>

                <b-form-group id="new-beneficiaire-password-group" label="Votre mot de passe" label-for="new-beneficiaire-password-input" class="mandatory-input">
                  <b-form-input
                    v-model="password" type="password"
                    maxlength="249"
                    placeholder="Mot de passe"
                    id="new-beneficiaire-password-input"
                    @input="$v.password.$touch()"
                    :state="$v.password.$dirty ? !$v.password.$error : null">
                  </b-form-input>
                  <div class="form-error" v-if="!$v.password.password_validation">Votre mot de passe doit contenir au moins 8 caractères, dont une majuscule, un chiffre et un caractère spécial.</div>
                </b-form-group>

                <b-form-group id="new-beneficiaire-confirm-password-group" label="Confirmez votre mot de passe"
                  label-for="new-beneficiaire-confirm-password-input" class="mandatory-input">
                  <b-form-input
                    v-model="confirmPassword" type="password"
                    maxlength="249"
                    placeholder="Mot de passe"
                    id="new-beneficiaire-confirm-password-input"
                    @input="$v.confirmPassword.$touch()"
                    :state="$v.confirmPassword.$dirty ? !$v.confirmPassword.$error : null">
                  </b-form-input>
                </b-form-group>

                <hr>

                <div class="row">
                  <div class="col-md-4">
                    <b-form-group id="new-beneficiaire-sex-group"
                      label="Vous êtes"
                      label-for="new-beneficiaire-sex-input"
                      class="mandatory-input">
                      <b-form-select
                        v-model="sex"
                        id="new-beneficiaire-sex-input"
                        @input="$v.sex.$touch()"
                        :state="$v.sex.$dirty ? !$v.sex.$error : null"
                        :options='genderOptions'>
                      </b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group id="new-beneficiaire-first_name-group" label="Prénom" label-for="new-beneficiaire-first_name-input" class="mandatory-input">
                      <b-form-input
                        v-model="firstName" type="text"
                        maxlength="29"
                        placeholder="Prénom"
                        id="new-beneficiaire-first_name-input"
                        @input="$v.firstName.$touch()"
                        :state="$v.firstName.$dirty ? !$v.firstName.$error : null">
                      </b-form-input>
                      <div class="form-error" v-if="$v.firstName.$error">Votre prénom doit comporter entre 2 et 30 caractères</div>
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group id="new-beneficiaire-last_name-group" label="Nom" label-for="new-beneficiaire-last_name-input" class="mandatory-input">
                      <b-form-input
                        v-model="lastName" type="text"
                        maxlength="149"
                        placeholder="Nom"
                        id="new-beneficiaire-last_name-input"
                        @input="$v.lastName.$touch()"
                        :state="$v.lastName.$dirty ? !$v.lastName.$error : null">
                      </b-form-input>
                      <div class="form-error" v-if="$v.lastName.$error">Votre nom doit comporter entre 2 et 150 caractères</div>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <b-form-group id="new-beneficiaire-other_first_names-group">
                      <template slot="label">Autre(s) prénom(s) <em>si pertinent</em></template>
                      <b-form-input
                        v-model="otherFirstNames" type="text"
                        maxlength="199"
                        placeholder="Nom marital"
                        id="new-beneficiaire-other_first_names-input"
                        @input="$v.otherFirstNames.$touch()"
                        :state="$v.otherFirstNames.$dirty ? !$v.otherFirstNames.$error : null">
                      </b-form-input>
                      <div class="form-error" v-if="$v.otherFirstNames.$error">Votre nom doit comporter entre 2 et 200 caractères</div>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <div v-if="$browserDetect.isSafari">
                      <b-form-group id="new-beneficiaire-birthdate-group" label="Date de naissance" label-for="new-beneficiaire-birthdate-input" class="mandatory-input">
                        <b-form-datepicker
                          id="new-beneficiaire-birthdate-input"
                          name="new-beneficiaire-birthdate-input"
                          locale="fr"
                          :max="maxBirthdate"
                          :show-decade-nav="true"
                          placeholder="Choisissez une date"
                          v-model="birthdate" class="mb-2"
                          @input="$v.birthdate.$touch()"
                          :state="$v.birthdate.$dirty ? !$v.birthdate.$error : null">
                        </b-form-datepicker>
                        <small class="text-danger" v-if="!$v.birthdate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
                      </b-form-group>
                    </div>
                    <div v-else>
                      <b-form-group id="new-beneficiaire-birthdate-group" label="Date de naissance" label-for="new-beneficiaire-birthdate-input" class="mandatory-input">
                        <b-form-input
                          v-model.trim.lazy="birthdate" type="date"
                          id="new-beneficiaire-birthdate-group"
                          placeholder="AAAA-MM-DD"
                          @input="$v.birthdate.$touch()"
                          :state="$v.birthdate.$dirty ? !$v.birthdate.$error : null">
                        </b-form-input>
                        <div v-if="$browserDetect.isIE" class="text-info">
                          <small>Vous utilisez Internet Explorer, veuillez entrer votre
                          date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
                        </div>
                        <div class="form-error" v-if="!$v.birthdate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</div>
                      </b-form-group>
                    </div>
                  </div>
                </div>

                <b-form-group id="new-beneficiaire-situation-group"
                  label="Votre situation maritale"
                  label-for="new-beneficiaire-situation-input"
                  class="mandatory-input">
                  <b-form-select
                    v-model="situation"
                    id="new-beneficiaire-situation-input"
                    @input="$v.situation.$touch()"
                    :state="$v.situation.$dirty ? !$v.situation.$error : null"
                    :options='situationTypeArrayForm'>
                  </b-form-select>
                </b-form-group>

                <div v-if="situation === 'MAR' || situation === 'PACS' || situation === 'VIEMAR'">
                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group id="new-beneficiaire-conjointFirstName-group" label="Prénom de votre conjoint" label-for="new-beneficiaire-conjointFirstName-input" class="mandatory-input">
                        <b-form-input
                          v-model="conjointFirstName" type="text"
                          maxlength="150"
                          placeholder="Prénom"
                          id="new-beneficiaire-conjointFirstName-input"
                          @input="$v.conjointFirstName.$touch()"
                          :state="$v.conjointFirstName.$dirty ? !$v.conjointFirstName.$error : null">
                        </b-form-input>
                        <div class="form-error" v-if="$v.conjointFirstName.$error">Le prénom doit comporter entre 2 et 150 caractères</div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group id="new-beneficiaire-conjointLastName-group" label="Nom de votre conjoint" label-for="new-beneficiaire-conjointLastName-input" class="mandatory-input">
                        <b-form-input
                          v-model="conjointLastName" type="text"
                          maxlength="149"
                          placeholder="Nom"
                          id="new-beneficiaire-conjointLastName-input"
                          @input="$v.conjointLastName.$touch()"
                          :state="$v.conjointLastName.$dirty ? !$v.conjointLastName.$error : null">
                        </b-form-input>
                        <div class="form-error" v-if="$v.conjointLastName.$error">Le nom doit comporter entre 2 et 150 caractères</div>
                      </b-form-group>
                    </div>
                  </div>

                  <b-form-group>
                    <label>Votre conjoint est-il également <strong>intermittent</strong> ?</label>
                    <b-form-radio-group
                      id="isConjointIntermittent-input"
                      v-model="isConjointIntermittent"
                      :options="trueFalseOptions"
                      name="isConjointIntermittent-input"
                    ></b-form-radio-group>
                  </b-form-group>
                </div>


                <div class="row">
                  <div class="col-md-6">
                    <b-form-group id="new-beneficiaire-phone-number-group" label="Votre numéro de téléphone portable" label-for="new-beneficiaire-phone-number-input" class="mandatory-input">
                      <b-form-input
                        type="tel" maxlength="10"
                        v-model.trim.lazy="phoneNumber"
                        id="new-beneficiaire-phone-number-input"
                        @input="$v.phoneNumber.$touch()"
                        :state="$v.phoneNumber.$dirty ? !$v.phoneNumber.$error : null"
                        placeholder="Entrez votre numéro de téléphone portable">
                      </b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group id="new-beneficiaire-other-phone-number-group"
                      label-for="new-beneficiaire-other-phone-number-input">
                      <template slot="label">Votre autre numéro de téléphone fixe ou portable <em>(facultatif)</em></template>
                      <b-form-input
                        type="tel" maxlength="10"
                        v-model.trim.lazy="otherPhoneNumber"
                        id="new-beneficiaire-other-phone-number-input"
                        @input="$v.otherPhoneNumber.$touch()"
                        :state="$v.otherPhoneNumber.$dirty ? !$v.otherPhoneNumber.$error : null"
                        placeholder="Second numéro de téléphone">
                      </b-form-input>
                    </b-form-group>
                  </div>
                </div>


                <b-form-group id="new-beneficiaire-address-group" label="Votre adresse" label-for="new-beneficiaire-address-input" class="mandatory-input">
                  <b-form-input
                    v-model="address" type="text"
                    maxlength="149"
                    placeholder="Renseignez votre adresse"
                    id="new-beneficiaire-address-input"
                    @input="$v.address.$touch()"
                    :state="$v.address.$dirty ? !$v.address.$error : null">
                  </b-form-input>
                </b-form-group>

                <div class="row">
                  <div class="col-md-6">
                    <b-form-group id="new-beneficiaire-postalCode-group" label="Votre code postal" label-for="new-beneficiaire-postalCode-input" class="mandatory-input">
                      <b-form-input
                        v-model.trim="postalCode" type="text"
                        maxlength="5"
                        placeholder="Code postal"
                        id="postalCode-input"
                        @input="$v.postalCode.$touch()"
                        :state="$v.postalCode.$dirty ? !$v.postalCode.$error : null">
                      </b-form-input>
                      <div class="form-error" v-if="!$v.postalCode.minLength || !$v.postalCode.maxLength ">Votre cote postal doit comporter 5 chiffres</div>
                      <div class="form-error" v-if="!$v.postalCode.numeric">Votre code postal ne peut contenir que des chiffres</div>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group id="new-beneficiaire-city-group" label="Votre ville" label-for="new-beneficiaire-city-input" class="mandatory-input">
                      <b-form-input
                        v-model="city" type="text"
                        maxlength="149"
                        placeholder="Ville"
                        id="new-beneficiaire-city-input"
                        @input="$v.city.$touch()"
                        :state="$v.city.$dirty ? !$v.city.$error : null">
                      </b-form-input>
                    </b-form-group>
                  </div>
                </div>

                <hr>

                <b-form-group id="new-beneficiaire-activite-group"
                  label="Vous êtes"
                  label-for="new-beneficiaire-activite-input"
                  class="mandatory-input">
                  <b-form-select
                    v-model="activite"
                    id="new-beneficiaire-activite-input"
                    @input="$v.activite.$touch()"
                    :state="$v.activite.$dirty ? !$v.activite.$error : null"
                    :options='activiteTypeArrayForm'>
                  </b-form-select>
                </b-form-group>

                <b-form-group  id="new-beneficiaire-metier-group"
                  label="Votre métier exercé"
                  label-for="new-beneficiaire-metier-input"
                  class="mandatory-input">
                  <b-form-input
                    v-model.trim.lazy="metier" type="text"
                    placeholder="Métier"
                    id="new-beneficiaire-metier-group"
                    @input="$v.metier.$touch()"
                    :state="$v.metier.$dirty ? !$v.metier.$error : null">
                  </b-form-input>
                </b-form-group>

                <b-form-group id="new-beneficiaire-muna-group" label-for="new-beneficiaire-muna-input">
                  <template slot="label">Votre numéro de Muna, si connu <em>(facultatif)</em></template>
                  <b-form-input
                    id="new-beneficiaire-muna-input"
                    type="text" maxlength="11"
                    v-model.trim.lazy="muna"
                    @input="$v.muna.$touch()"
                    :state="$v.muna.$dirty ? !$v.muna.$error : null"
                    placeholder="XXXXXXXX.XX">
                  </b-form-input>
                  <small class="text-info">Votre Muna comporte 8 chiffres, 1 point et 2 chiffres (XXXXXXXX.XX)</small>
                </b-form-group>

                <p>
                  Une seule demande d'aide sera recevable par foyer, même si les deux parents sont éligibles.<br>
                  <strong>Les demandes de remboursements seront traitées par ordre d’arrivée (date de dépôt) et attribuées après 
                  vérification de leur éligibilité dans la limite des fonds mis à disposition par le Ministère de la Culture.</strong>
                </p>
                <b-form-group id="new-beneficiaire-is_attestation_honneur-politique-group" label-for="new-beneficiaire-is_attestation_honneur-politique-input">
                  <b-form-checkbox
                    v-model="isAttestationHonneur"
                    id="new-beneficiaire-is_attestation_honneur-politique-input">
                    Je certifie sur l’honneur ne percevoir aucune autre aide liée à la garde d’enfants (aide du département, de la région, d’une entreprise par exemple)
                    pour les dépenses dont je demande le remboursement et certifie l’exactitude des informations renseignées.
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group id="new-beneficiaire-has_accepted_conditions-politique-group" label-for="new-beneficiaire-has_accepted_conditions-politique-input">
                  <b-form-checkbox
                    v-model="hasAcceptedConditionsPolitique"
                    id="new-beneficiaire-has_accepted_conditions-politique-input">
                    En cochant cette case, je certifie avoir lu et accepté les
                    <router-link to='/conditions-utilisations' target="_blank">Conditions Générales d’Utilisation</router-link>
                    et la <router-link to='/politique-de-confidentialite' target="_blank">Politique de Confidentialité </router-link>.
                  </b-form-checkbox>
                </b-form-group>

                <small class="text-danger" v-if="isExistingUserUsername"> {{ username }} existe déjà. Veuillez choisir un autre nom d'utilisateur</small>
                <b-button
                  pill block :variant="createBeneficiaireButtonStyle" class="mt-2"
                  @click='createBeneficiaire'
                  :disabled="isInvalidRegister">
                    <b-spinner small label="Create Beneficiaire Spinner" v-if="createBeneficiaireInProcess"></b-spinner> {{ createBeneficiaireButtonText }}
                </b-button>

                <p class="mt-2">
                  <small><em>
                    Les informations collectées dans le présent formulaire sont nécessaires
                    à l’étude et à la gestion de votre dossier. Elles peuvent également être
                    utilisées à des fins de statistiques propres à cette aide. Ces données ne sont
                    conservées que pour la durée nécessaire à la gestion de votre dossier puis jusqu’à expiration
                    des délais légaux de prescription applicables.<br>
                    Conformément à la loi Informatique et Libertés du 6 janvier 1978 et
                    au Règlement Général sur la Protection des Données du 27 avril 2016,
                    vous bénéficiez, sous réserve de justifier de votre identité, de droits d’accès,
                    de rectification et d’effacement des données vous concernant, d’opposition et de
                    limitation du traitement, ainsi que du droit à la portabilité de vos données.
                    Ces droits peuvent être exercés par courrier électronique à
                    <a href="mailto:dpo@audiens.org">dpo@audiens.org</a>
                    ou par courrier postal à DPO - Audiens, 74 rue Jean Bleuzen, 92170 VANVES.
                  </em></small>
                </p>
              </b-form>
            </div>


            <!-- Compte créé -->

            <div v-if="isCreated">
              <h2 class="text-center">
                Votre compte a bien été créé.
              </h2>
              <p class="mt-3">
                Vous pouvez dès maintenant vous connecter pour accéder à votre tableau de bord et faire votre demande d'aide
                à la garde d'enfants
              </p>

              <b-button
                pill block variant="outline-info" class="mt-2 btn-lg"
                @click="$router.push('/login')">
                Je me connecte
              </b-button>

            </div>

            <hr>

            <div class="row text-center">
              <div class="col-12">
                <contactComponent />
              </div>
            </div>

          </div>
        </div>
    </div>
  </div>
</template>

<script>

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email, sameAs, numeric, integer} from 'vuelidate/lib/validators'
import { APIDisconnectedService } from '@/api/APIDisconnected'
import contactComponent from '@/components/contactComponent'
import { password_validation, isDate, isMuna } from '@/validators/validators'
import formTitleComponent from '@/components/formTitleComponent'

const apiDisconnectedService = new APIDisconnectedService()

export default {
  name: 'BeneficiaireRegister',
  components: {
    contactComponent,
    formTitleComponent
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    return {

      isCreated: false, // false : Compte en cours de création ; true : Compte créé

      username: '',
      password: '',
      confirmPassword: '',

      sex: 'H',
      firstName: '',
      lastName: '',
      otherFirstNames: '',
      birthdate: '',
      maxBirthdate: today,
      phoneNumber: '',
      otherPhoneNumber: '',
      address: '',
      postalCode: '',
      city: '',
      situation: '',

      conjointFirstName: '',
      conjointLastName: '',
      isConjointIntermittent: false,

      activite: '',
      metier: '',
      muna: '',

      hasAcceptedConditionsPolitique: false,
      isAttestationHonneur: false,

      activiteTypeArrayForm: [
        { text: 'Artiste', value: 'ART' },
        { text: 'Technicien', value: 'TEC' },
      ],

      situationTypeArrayForm: [
        { text: 'Célibataire', value: 'CELIB' },
        { text: 'Marié(e)', value: 'MAR' },
        { text: 'Divorcé(e)', value: 'DIV' },
        { text: 'Séparé(e)', value: 'SEP' },
        { text: 'Pacsé(e)', value: 'PACS' },
        { text: 'Vie maritale / Concubinage', value: 'VIEMAR' },
      ],

      trueFalseOptions: [
        { text: 'Oui', value: true },
        { text: 'Non', value:  false },
      ],

      genderOptions: [
        { text: 'Un homme', value: 'H' },
        { text: 'Une femme', value:  'F' },
        { text: 'Ne se prononce pas', value:  'N' },
      ],

      createBeneficiaireButtonText: 'Créer votre compte',
      createBeneficiaireButtonStyle: 'outline-info',
      createBeneficiaireInProcess: false,

      isExistingUserUsername: false
    }
  },

  computed: {
    isInvalidRegister () {
      if (this.$v.username.$invalid || this.$v.password.$invalid ||
      this.$v.confirmPassword.$invalid || this.$v.firstName.$invalid || this.$v.lastName.$invalid ||
      this.$v.otherFirstNames.$invalid || this.$v.birthdate.$invalid || this.$v.phoneNumber.$invalid ||
      this.$v.otherPhoneNumber.$invalid || this.$v.address.$invalid || this.$v.postalCode.$invalid ||
      this.$v.city.$invalid || this.$v.situation.$invalid || this.$v.conjointFirstName.$invalid ||
      this.$v.conjointLastName.$invalid || this.$v.isConjointIntermittent.$invalid ||
      this.$v.activite.$invalid || this.$v.metier.$invalid || this.$v.muna.$invalid ||
      !this.hasAcceptedConditionsPolitique || !this.isAttestationHonneur || this.createBeneficiaireInProcess) {
        return true
      }
      return false
    }
  },

  validations: {
    username: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required,
      email
    },
    password: {
      maxLength: maxLength(250),
      required,
      password_validation
    },
    confirmPassword: {
      maxLength: maxLength(250),
      required,
      sameAsPassword: sameAs('password'),
    },
    sex: {
      required
    },
    firstName: {
      minLength: minLength(2),
      maxLength: maxLength(30),
      required
    },
    lastName: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required
    },
    otherFirstNames: {
      minLength: minLength(2),
      maxLength: maxLength(200),
    },
    birthdate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    phoneNumber: {
      required,
      integer,
      minLength: minLength(10),
      maxLength: maxLength(10)
    },
    otherPhoneNumber: {
      integer,
      minLength: minLength(10),
      maxLength: maxLength(10)
    },
    address: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(150)
    },
    postalCode: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(5),
      numeric
    },
    city: {
      required
    },
    situation: {
      required
    },
    conjointFirstName: {
      minLength: minLength(2),
      maxLength: maxLength(150)
    },
    conjointLastName: {
      minLength: minLength(2),
      maxLength: maxLength(150)
    },
    isConjointIntermittent: {
      required
    },
    activite: {
      required,
    },
    metier: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(70),
    },
    muna: {
      isMuna,
    },
  },

  methods: {
    createBeneficiaire () {
      this.createBeneficiaireInProcess = true
      this.createBeneficiaireButtonText = "Création en cours"
      this.createBeneficiaireButtonStyle = 'secondary'

      apiDisconnectedService.createNewBeneficiaire(
        this.username, this.password, this.confirmPassword, this.sex, this.firstName, this.lastName,
        this.activite, this.metier, this.otherFirstNames, this.address, this.postalCode, this.city,
        this.birthdate, this.phoneNumber, this.otherPhoneNumber, this.situation,
        this.conjointFirstName, this.conjointLastName, this.isConjointIntermittent,
        this.muna, this.hasAcceptedConditionsPolitique, this.isAttestationHonneur)
      .then(() => {
        this.isCreated = true
      })
      .catch((error) => {
        if (error.response && error.response.data.username && error.response.data.username[0] === "Un utilisateur avec cet email existe déjà") {
          this.isExistingUserUsername = true
        }
        else if (error.response && error.response.data.muna && error.response.data.muna[0] === 'MUNA_ALREADY_EXISTING') {
          this.$refs.munaExistingErrorModal.show()
        }
        else {
          this.$refs.beneficiaireCreationAPIErrorModal.show()
          throw new Error('BeneficiaireRegister createBeneficiaire API Error : ' + String(error))
        }
      })
      .finally(() => {
        this.createBeneficiaireInProcess = false
        this.createBeneficiaireButtonText = "Créer votre compte"
        this.createBeneficiaireButtonStyle = 'outline-info'
      })

    }
  }
}
</script>
