import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'

export class APIDisconnectedService {

  createNewBeneficiaire (
    username, password, confirm_password, sex, first_name, last_name,
    activite, metier, other_first_names, address, code_postal, city,
    birthdate, phone_number, other_phone_number, situation, 
    conjoint_first_name, conjoint_last_name, is_conjoint_intermittent,
    muna, has_accepted_conditions_politique, is_attestation_honneur) {

    const url = apiBaseUrl + `/create-beneficiaire/`
    var headers = {
      'Content-Type': 'application/json',
    }

    return axios.post(
      url,
      {
        'username': username,
        'password': password,
        'confirm_password': confirm_password,
        'sex': sex,
        'first_name': first_name,
        'last_name': last_name,
        'activite': activite,
        'metier': metier,
        'other_first_names': other_first_names,
        'address': address,
        'code_postal': code_postal,
        'city': city,
        'birthdate': birthdate,
        'phone_number': phone_number,
        'other_phone_number': other_phone_number,
        'situation': situation,
        'conjoint_first_name': conjoint_first_name,
        'conjoint_last_name': conjoint_last_name,
        'is_conjoint_intermittent': is_conjoint_intermittent,
        'muna': muna,
        'has_accepted_conditions_politique': has_accepted_conditions_politique,
        'is_attestation_honneur': is_attestation_honneur
      },
      {
        'headers': headers,
      }
    )
  }
}
