<template>
  <div class="contactComponent">
    <div class="row">
      <div class="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-12 text-center">
        <strong>Nous contacter</strong>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12 text-center">
        <v-icon name="envelope" class="mr-2" style="color:#A4A4A4"/>
        <span><!--Via notre <router-link class="text-danger" to="/contact">formulaire de contact</router-link>
        ou -->
        En envoyant directement un mail à <a href="mailto:gardenfant@audiens.org">gardenfant@audiens.org</a></span><br>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contactComponent',
}
</script>
